<template>
  <div class="custom-height mt-5 pt-5 mb-5">
    <div v-if="orderId > 0" class="d-flex flex-column align-items-center">
      <div class="card">
        <div class="card-body">
          <div class="d-block m-auto">
            <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M45.6002 26.2202L45.6003 26.2202L45.6061 26.2142C46.5636 25.2237 46.572 23.6583 45.6002 22.6864C44.6316 21.7178 43.035 21.7178 42.0664 22.6864L28.7133 36.0395L22.9335 30.2598C21.9649 29.2912 20.3684 29.2912 19.3998 30.2598C18.4312 31.2284 18.4312 32.8249 19.3998 33.7935L26.9464 41.3402C27.4125 41.8062 28.0459 42.0733 28.7133 42.0733C29.3808 42.0733 30.0142 41.8062 30.4802 41.3402L45.6002 26.2202ZM6.33331 32C6.33331 17.5828 18.0828 5.83331 32.5 5.83331C46.9172 5.83331 58.6666 17.5828 58.6666 32C58.6666 46.4172 46.9172 58.1666 32.5 58.1666C18.0828 58.1666 6.33331 46.4172 6.33331 32Z"
                fill="#509920" stroke="#509920" />
            </svg>
          </div>
          <div class="heading-08 text-success-07 text-center mt-3">
            Comanda a fost plasată cu succes!
          </div>
          <div class="heading-09 text-neutral-08 mt-3 text-center">
            <div>
              Numărul comenzii: <b>#{{ orderId }}</b>
            </div>
            <div>
              Data ridicării: <b>{{ pickUpDate }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-heading-03 text-neutral-06 text-center mt-5 pe-3 ps-3 p-sm-0" style="max-width: 702px">
        Acum că ai descoperit produsele noastre de calitate, îți recomandăm să explorezi și alte
        oferte irezistibile. Continuă cumpărăturile pentru a descoperi surprize și reduceri
        exclusive.
      </div>
      <div class="row mt-5 mt-sm-4 justify-content-between">
        <div class="col-auto">
          <router-link :to="{ name: 'HomePage' }" class="button btn-medium-wider btn-primary-outlined fw-semibold">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.97502 5.44165L2.91669 10.5L7.97502 15.5583" stroke="#D6366C" stroke-width="1.5"
                stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.0834 10.5H3.05835" stroke="#D6366C" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            Înapoi acasă
          </router-link>
        </div>
        <div class="col-auto">
          <router-link :to="{ name: 'Products' }"
            class="button btn-medium-wider btn-primary-solid h-100 pe-sm-5 ps-sm-5">
            Produse <img src="../../assets/images/icons/arrow-right-20x20.svg" />
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="d-flex flex-column align-items-center">
      <div class="card">
        <div class="card-body">
          <div class="d-block m-auto">
            <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M27.4997 0.333252C12.8063 0.333252 0.833008 12.3066 0.833008 26.9999C0.833008 41.6933 12.8063 53.6666 27.4997 53.6666C42.193 53.6666 54.1663 41.6933 54.1663 26.9999C54.1663 12.3066 42.193 0.333252 27.4997 0.333252ZM36.4597 33.1333C37.233 33.9066 37.233 35.1866 36.4597 35.9599C36.0597 36.3599 35.553 36.5466 35.0463 36.5466C34.5397 36.5466 34.033 36.3599 33.633 35.9599L27.4997 29.8266L21.3663 35.9599C20.9663 36.3599 20.4597 36.5466 19.953 36.5466C19.4463 36.5466 18.9397 36.3599 18.5397 35.9599C17.7663 35.1866 17.7663 33.9066 18.5397 33.1333L24.673 26.9999L18.5397 20.8666C17.7663 20.0933 17.7663 18.8133 18.5397 18.0399C19.313 17.2666 20.593 17.2666 21.3663 18.0399L27.4997 24.1733L33.633 18.0399C34.4063 17.2666 35.6863 17.2666 36.4597 18.0399C37.233 18.8133 37.233 20.0933 36.4597 20.8666L30.3263 26.9999L36.4597 33.1333Z"
                fill="#DD2531" />
            </svg>

          </div>
          <div class="heading-06 text-danger-05 text-center mt-3">
            A fost o eroare, va rugam
            incercati mai tarziu!
          </div>
        </div>
      </div>
      <div class="sub-heading-03 text-neutral-06 text-center mt-5 pe-3 ps-3 p-sm-0" style="max-width: 702px">
        Ne cerem scuze pentru neplăcerile cauzate de eroarea apărută la procesarea plății dumneavoastră. Pentru a vă oferi suport rapid și a rezolva situația, vă rugăm să contactați echipa noastră de suport.
      </div>
      <div class="row mt-5 mt-sm-4 justify-content-between">
        <div class="col-auto">
          <router-link :to="{ name: 'HomePage' }" class="button btn-medium-wider btn-primary-outlined fw-semibold">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.97502 5.44165L2.91669 10.5L7.97502 15.5583" stroke="#D6366C" stroke-width="1.5"
                stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.0834 10.5H3.05835" stroke="#D6366C" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            Înapoi acasă
          </router-link>
        </div>
        <div class="col-auto">
          <router-link :to="{ name: 'Contact' }"
            class="button btn-medium-wider btn-primary-solid h-100 pe-sm-5 ps-sm-5">
            Support
          </router-link>
        </div>
      </div>
    </div>
    <div class="bg-img"></div>
  </div>
</template>

<script>
export default {
  name: 'ThanksForOrderView',
  props: {
    pickUpDate: String,
    orderId: String,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>

.error-text {
  color:#DD2531
}
.bg-img {
  position: absolute;
  bottom: 101px;
  z-index: -1;
  background-image: url('../../assets/images/bg-img-thanks-for-order.webp');
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  height: 400px;
  width: 100%;
}

.btn-medium-wider {
  padding: 8px 24px;
}
@media (min-width: 576px) {
  .btn-medium-wider {
    padding: 12px 32px;
  }
}

.card-body {
  padding: 64px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  box-shadow: 10px 10px 40px -10px #00000040;
  border-radius: 12px;
  border: 0px;
  max-width: 427px;
  width: 100%;
}
@media (min-width: 576px) {
  .custom-height {
    height: 77vh;
  }
}
</style>
